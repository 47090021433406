import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ArticleCarouselLoading = ({ isLatestArticles = true }) => {
  return (
    <div
      className={`carousel-loading ${
        isLatestArticles ? 'latest-article-carousel' : ''
      }`}
    >
      <div className="loading-list">
        {!isLatestArticles && (
          <div className="loading-item">
            <Skeleton />
          </div>
        )}
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
        <div className="loading-item">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default ArticleCarouselLoading;
